@import url('../animations.css');
@import url('../variables.css');


/* CONTENEDOR MAYOR */

.laFinca-contenedorMayor  {
 padding-top: 100px;
 margin-left: 20px;
 margin-right: 20px;
 padding-bottom: 20px;
/*  background-image:url('../../../public/Images/biografia/fincaDron.jpeg'); */
}

/* TITULO INICIAL DE LA PÁGINA */

.laFinca-contenedorMayor .biografia-experience {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
    margin-right: 10px;
    padding-top: 10px;
}
.laFinca-contenedorMayor .biografia-experience p {
    font-family: var(--fuente-regular);
    color: 'black';
    font-size: 40px;
  /*   font-weight: bold; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align:center
}

/* CARACTERISTICAS DE LA FINCA CONTENEDOR Y SUB CONTENEDORES: */

.laFinca-contenedorMayor .laFinca-caracteristicas-contenedor {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    width: 100%;
    max-height: 450px;
    margin-left: 20px;
    margin-right: 10px;
    margin-top: 40px;
    
}
 .laFinca-contenedorMayor .laFinca-caracteristicas-contenedor .laFinca-caracteristicas-tarjeta {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     justify-content: start;
     width: 100%;
    max-height: 450px;
    min-height: 200px;
    margin-left: 20px;
    margin-right: 10px;

} 

.laFinca-contenedorMayor .laFinca-caracteristicas-contenedor .laFinca-caracteristicas-tarjeta {
    font-family: var(--fuente-terciaria);
    font-size: 18px;
    text-align: justify;
    align-items: flex-start;
}

.laFinca-contenedorMayor .laFinca-caracteristicas-contenedor .laFinca-caracteristicas-tarjeta .laFinca-titulo-caracteristica{
    font-family: var(--fuente-regular);
    font-size: 27px;
    color: var(--colorIcons);
    margin-bottom: 10px;
    font-weight: 'bold';
}


/* CARACTERISTICAS DE LAS IMAGENES PEGADAS SIGUIENTES COMO BANDA: CONTENEDOR Y SUB CONTENEDORES DE IMAGENES: */

.laFinca-contenedorMayor .laFinca-imagenes-contenedor {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    width: 100%;
    max-height: 450px;
  /*   margin-left: 20px; */
    margin-right: 10px;
    margin-top: 5px;
}

.laFinca-contenedorMayor .laFinca-imagenes-contenedor .laFinca-caracteristicas-tarjeta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    width: 100%;
    max-height: 450px;
    min-height: 200px;
} 
.laFinca-contenedorMayor .laFinca-imagenes-contenedor .laFinca-caracteristicas-tarjeta img{
 object-fit: cover;
 height: 100%;
 min-height: 200px;
 max-height: 200px;
}
.laFinca-contenedorMayor .laFinca-imagenes-contenedor .laFinca-caracteristicas-tarjeta img:hover {
    transform: none; 
  }


/* SECCION TEXTO COMPLETO DE TODA LA DESCRIPCION DE LA FINCA: */

.laFinca-contenedorMayor .biografia-texto-completo-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.laFinca-contenedorMayor .biografia-texto-completo-container .titulo-principal{
    font-family: var(--fuente-regular);
    font-size:30px;
    color: var(--colorIcons);
    margin-bottom: 10px;
    margin-top: 10px;
}
.laFinca-contenedorMayor .biografia-texto-completo-container .titulo-secundario{
    font-family: var(--fuente-regular);
    font-size:30px;
    color: var(--colorIcons);
    margin-top: 10px;
    margin-bottom: 12px;
}

.laFinca-contenedorMayor .biografia-texto-completo-container .finca-caracteristicas p{
    font-family: var(--fuente-terciaria);
    font-size:18px;
    color: black;
    text-align: justify;
    margin-bottom: 0px;
}

/* CONTENEDOR DEL BOTON DESCARGAR FICHA TÉCNICA: */

.laFinca-contenedorMayor .laFinca-descargaFicha-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.laFinca-descargaFicha-container .container-icono-boton { 
    width: 100%;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: justify;
    padding: 5px;
    font-family: var(--fuente-terciaria);
    font-weight:bold;
    margin-top: 10px;
    margin-bottom: 2px;
    color: var(--colorIcons);
  }

  .laFinca-descargaFicha-container .container-icono-boton .iconoDescargaFichaFinca {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: var(--colorIcons);
    font-family: var( --fuente-regular);
    cursor: pointer;
 
}
  .laFinca-descargaFicha-container .container-icono-boton .descargarFichaFinca-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 25px;
    color:var(--colorSubTitle);
    font-family: var(--fuente-regular);
    background-color: transparent;
    border: 0px solid rgba(0, 0, 0);
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-top: 15px;
    margin-left: 15px;
 
}

/* CONTENEDOR DEL BOTON VOLVER AL INICIO: */

.laFinca-contenedorMayor .laFinca-volver {
    margin-top: 10px;
    margin-bottom: 20px;
}

.laFinca-volver li { 
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    padding: 5px;
    font-family: var(--fuente-terciaria);
    font-weight:bold;
    margin-top: 5px;
    margin-bottom: 2px;
    color: var(--colorIcons);
  }


