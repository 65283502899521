header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 20px;
    height: 85px;
    width: 100%;
    position: fixed;
    z-index: 10;
    background-color: white;
}

@media (max-width: 700px) {
    header {
        max-width: 700px;
    }
}

header .logo {
    height: 85px;
    
}

header .logo img {
    height: 85px;
    max-width: 85px;
}

header .info {
    width: 100%;
    background-color: white;
    opacity: 0;
/*     transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease; */
}

header .info.active {
    opacity: 1;
    /* El componente se vuelve visible */
 /*    transform: translateY(0); */
    /* Vuelve a su posición normal */
}

header .menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
header .menu ul {
    width: 100%;
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

header .menu li {
    list-style-type: none;
}

header .menu li .dropdown{
    display: flex
}

header .menu ul a {
    width: 100%;
    padding: 10px;
    text-align: center;
    border-top: 1px solid var(--colorIcons);
    list-style-type: none;
    text-decoration: none;
    color: black;
}
header .menu li a {
    border-top: 0px solid var(--colorIcons);
}
header .menu ul a:last-child {
    border-bottom: 1px solid var(--colorIcons);
}
header .menu li a:last-child {
    border-bottom: 0px solid var(--colorIcons);
}



header .menu ul a:hover{
    background-color: var(--colorIcons);
}


header .redes {
    padding: 10px;
    margin-right: 30px; 
    width: 150px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

header .redes ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px;
    border-bottom: 1px solid var(--colorIcons);
}
header .paises-contenedor {
    border-top: 1px solid var(--colorIcons);
    border-bottom: 1px solid var(--colorIcons);
}
header .paises-contenedor li{
    margin-top: 5px;
    margin-bottom: 5px;
    align-self: center;
}

header .redes ul li {
    padding: 0 0px;
    list-style-type: none;
    text-decoration: none;
}

header .redes ul li {
    text-decoration: none; 
  color: inherit;
}

header .burger-menu {
    width: 85px;
    max-height: fit-content;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

header .icono-dorado {
    fill: var(--colorIcons);
    stroke: var(--colorIcons);
    height: 30px;/* AGREGADO EL 19 FEB PRUEBA  */
    width: 30px;/* AGREGADO EL 19 FEB PRUEBA  */
    margin-top: 10px;
}
header .redes ul li a .icono-dorado {
    height: 35px;
    fill: var(--colorIcons);
    stroke: var(--colorIcons);
}

header .redes ul li {
    padding: 0 10px; 
    margin-left: 10px;
    list-style-type: none;
    margin-bottom: 10px;
    margin-bottom: 10px;
  }
  
/* Estilos para el menú desplegable */


/* Mostrar el menú desplegable cuando se activa */

header .menu .dropdown-menu.active {
  display: block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

header .dropdown-menu {
    max-width: 100px; /* Ajusta el ancho máximo según tus preferencias */
    max-height: 100px; /* AGREGADO EL 19 FEB PRUEBA */
}

header .menu .dropdown-menu {
    position: absolute; /* Posición absoluta en relación con el encabezado */
    top: 70%; /* La altura del encabezado */
   /*  left: 20; */
    width: 80%;
    background-color: white; 
    flex-direction: column;
    z-index: 999; 
    display: none; /* Inicialmente oculto */
  }
  
  /* Estilos para el título "Winemaker" en el menú desplegable */
  
  header .menu .dropdown-menu .titulo {
    font-size: 16px; /* Mismo tamaño de fuente que el encabezado */
    text-decoration: none;
    padding: 10px; /* Espaciado igual al del encabezado */
    text-align: center; /* Centrado */
    background-color: white;
    width: 100%; /* Fondo blanco */
    margin-top: 5px;
    border-top: 1px solid var(--colorIcons);
    display: none;
  }

  header .menu ul li {
    width: 100px;
    text-align: center;
    list-style: none;
    text-decoration: none;
    font-size: 20px;
    color: var(--colorIcons); 
    font-weight: 600;
  
   
}

  header .menu ul li:hover {
    cursor: pointer;

  }
  
  
  /* Estilos para los elementos del menú desplegable */

  header .menu .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  header .menu .dropdown-menu ul li {
    font-size: 16px; /* Mismo tamaño de fuente que el encabezado */
    padding: 10px; /* Espaciado igual al del encabezado */
    text-align: center; /* Centrado */
    background-color: white; /* Fondo blanco */
     border-top: 1px solid var(--colorIcons);  /* Borde superior igual al del encabezado */
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}



/* Media query Tablets */
@media (min-width: 768px) {
   /*  header .burger-menu {
        display: none;
    } */

    header .menu {
        height: 85px;
        width: 600px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    header .menu ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: stretch;
        align-items: center;
        flex-direction: row;
    }

    header .menu ul li {
       /*  width: 100px; */
        flex: 1;
        text-align: center;
        list-style: none;
        font-weight: normal;
        flex-direction: row;
        flex-wrap: nowrap;
    }
 /*    flex: 1; 
    text-align: center;
    list-style: none;
    font-weight: normal; */

 
   
    header .menu ul a:hover{
        background-color: #ffffff00
    }

    header .menu ul a {
        width: 100px;
        padding: 0;
        border: none
    }

    header .menu ul a:last-child {
        border-bottom: none
    }

    header .banderas {
        width: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    header .banderas ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: flex-start;
    }

    header .redes {
       
        width: 150px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    header .redes ul {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        border-bottom: 0px solid var(--colorIcons);
    }
    header .redes ul li img {
        margin-right: 10px;
    }

  header .redes ul li {
        padding: 0;
    } 

    header .burger-menu {
        width: 85px;
        max-height: fit-content;
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* Media query para tamaños de celulares pequeños */
@media (max-width: 535px) {
    header .burger-menu {
        /* Estilos para el ícono de menú en tamaños pequeños */
        width: 60px;
        max-height: fit-content;
        padding: 15px;
    }
    /* header .burger-menu {
    width: 85px;
    max-height: fit-content;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

    header .menu {
        height: auto; /* Ajusta la altura según el contenido */
        width: 100%; /* Ancho completo */
        /* display: none; */ /* Inicialmente oculto */
    }

    header .menu ul {
        /* Estilos para la lista de elementos del menú */
        width: 100%;
        /* display: block; */ /* Cambia a un formato de bloque */
        text-align: center; 
    }

     /* Oculta el dropdown en pantallas pequeñas */
    header .menu .dropdown-menu {
    display: none;
    }

    header .menu ul li {
        /* Estilos para cada elemento de la lista del menú */
        width: 100%;
        text-align: center; /* Centra el texto */
        margin-bottom: 8px; /* Espacio entre elementos */
        list-style: none;
        font-weight: normal;
    }

    header .menu ul li .banderaPequeña {
        /* Estilos para cada elemento de la lista del menú */
        width: 9%;
        align-items: center;
        text-align: center; /* Centra el texto */
        margin-bottom: -3px; /* Espacio entre elementos */
        margin-top: 8px;
        list-style: none;
        font-weight: normal;
        margin-right: 8px;
    }

    header .redes {
        /* Estilos para la sección de redes sociales en tamaños pequeños */
        height: auto; /* Ajusta la altura según el contenido */
        width: 100%; /* Ancho completo */
        display: flex; /* Cambia a un formato de bloque */
        justify-content: center; /* Centra horizontalmente los elementos */
  

    header .redes ul {
        /* Estilos para la lista de redes sociales */
        width: 100%;
        display: flex;  /* Cambia a un formato de fila flex */
       justify-content: center;
       padding: 0;
    }

    header .redes ul li {
        /* Estilos para cada elemento de la lista de redes sociales */
        padding: 0;
        margin-bottom: 10px; /* Espacio entre elementos */
    }
}
}

  