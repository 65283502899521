/* ----------------------------------------------
 * Generated by Animista on 2023-11-10 16:24:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-scale-up-hor
 * ----------------------------------------
 */
 @-webkit-keyframes flip-scale-up-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(2.5) rotateX(-90deg);
            transform: scale(2.5) rotateX(-90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-180deg);
            transform: scale(1) rotateX(-180deg);
  }
}
@keyframes flip-scale-up-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(2.5) rotateX(-90deg);
            transform: scale(2.5) rotateX(-90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-180deg);
            transform: scale(1) rotateX(-180deg);
  }
}


/**
 * ----------------------------------------
 * animation flip-2-hor-top-1
 * ----------------------------------------
 */
 @-webkit-keyframes flip-2-hor-top-1 {
    0% {
      -webkit-transform: translateY(0) rotateX(0);
              transform: translateY(0) rotateX(0);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
            
    }
    100% {
      -webkit-transform: translateY(-100%) rotateX(-180deg);
              transform: translateY(-100%) rotateX(-180deg);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
              
    }
  }
  @keyframes flip-2-hor-top-1 {
    0% {
      -webkit-transform: translateY(0) rotateX(0);
              transform: translateY(0) rotateX(0);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: translateY(-100%) rotateX(-180deg);
              transform: translateY(-100%) rotateX(-180deg);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  
  /**
   * ----------------------------------------
   * animation rotate-center
   * ----------------------------------------
   */



  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  /**
   * ----------------------------------------
   * animation rotate-vert-center
   * ----------------------------------------
   */
  @-webkit-keyframes rotate-vert-center {
    0% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
    100% {
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    }
  }
  @keyframes rotate-vert-center {
    0% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
    100% {
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    }
  }
  
  /* ----------------------------------------
 * animation shadow-drop-2-center
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-center {
  70% {
    -webkit-transform: translateZ(30px);
            -webkit-box-shadow: 0 0 20px 5px var(--iconColor); /* Ajusta los valores aquí */
  }
  100% {
    -webkit-transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px var(--iconColor);
  }
}
@keyframes shadow-drop-2-center {
 /*  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  } */
  70% {
    transform: translateZ(30px);
    box-shadow: 0 0 20px 5px rgba(184, 146, 81, 1); /* Ajusta los valores aquí */
  }
  100% {
    transform: translateZ(50px);
    box-shadow: 0 0 20px 0px rgba(184, 146, 81, 1);
  }







}

/* ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-out-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-contract-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px);
            transform: translateZ(-500px);
    opacity: 0;
  }
}
@keyframes tracking-out-contract-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px);
            transform: translateZ(-500px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


