@import url('../animations.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  height: 100vh;
  font-family: var(--fuente-regular);
}
main {
  width: 100%;
  padding-top: 85px;
}
img {
  width: 100%;
}
header img {
  width: 150%;

}
ul {
  margin: 0;
}
li {
  list-style: none;
}

a {
  color: black;
  text-decoration: none;
}


/* formulario de contacto ------------------------*/


.Contacto form{
  background-color: white;
}


.formContainer {
background-color: white;

width: 100%;
  }
  form {
    background-color: white;
    flex-direction: column;
  }
  .select-container {
    background-color: white;
  }
  
  .inputContainer {
    background-color: white;

  }

  .inputWrapper {
    display: flex;
    flex-direction: column;


  }
  
  input, select, button {
    background-color: white;
   margin-bottom: 5px;
   justify-content: center;
   align-self: center;
  }
  
  input {
    background-color: white;
    width: 80%;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid var(--colorIcons);
  }

  input::placeholder {
    font-family: var(--fuente-regular);
    font-size: 15px;
    padding: 5px;
    color:black
  }
  
  
  select {
    background-color: white;
    width: 80%;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid var(--colorIcons);
    font-family: var(--fuente-regular);
    font-size: 15px;
  }

 
  .buttonSubmit{
    border-radius: 10px;
    padding: 5px;
    width: 20%;
    font-family: var(--fuente-regular);
    background-color: var(--colorIcons);
    border: 1px solid var(--colorIcons);
    font-size: 15px;
    
    &:hover {
      animation: shadow-drop-2-center 1.5s 2; 
      background-color: var(--colorIcons);
    }
  }
  
  .botones {
    background-color: white; 
  }

  button:hover {
    background-color: #585f67;
  }
  span.error-message  {
    color: var(--colorIcons); /* rgb(255, 102, 0);  */
    font-size: 15px; 
    margin-top: 0px; 
    margin-bottom: 10px;
    justify-content: center;
    align-self: center;
  }

  .envioExito {
    color: rgb(117, 75, 47); 
    align-self: center;
    text-align: center;
    font-size: 25px;  
  }
  
  
  /*
  button {
     margin-left: 0.5rem;
    margin-right: 0.5rem;
    background-color: #bcbfc2;
    color: #fff;
    border: none;
    cursor: pointer;
    width: auto;
    font-family: var(--fuente-regular); 
  }*/

