@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&family=Playfair+Display:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&family=Oswald:wght@400;500;600;700&family=Playfair+Display:ital@1&display=swap');


:root{
    --fuente-regular: 'Oswald'/* , 'Playfair Display', serif */;
    --fuente-secundaria: 'Oswald'/* 'Roboto', sans-serif */;
    --fuente-terciaria: 'Montserrat';
    --fuente-cuaternaria: 'Roboto';

    --colorSeleccion: #866a35;
    --colorSeleccion2: #826022;
    --colorIcons: #b89251;
    --colorIcons2: #e9c17e;
    --colorTitle: #4b4846;
    --colorBorder: #9c9693;
    --colorSubTitle: #29548F;
    --colorSubTitleMalbec: #A45651;
    --colorSubTitleCH: #97934C;

}